import { v4 as uuidv4 } from 'uuid';
import moment from 'moment'

export default {
    clear() {
        var setting = this.setting();
        localStorage.clear();
        this.setting(setting);
        //if(return_url != undefined) this.return_url(return_url);
    },
    logout() {
        if (this.save_user_id()) {
            var user_id = this.user_id();
            localStorage.clear();
            this.save_user_id("Y");
            this.user_id(user_id);
        } else {
            localStorage.clear();
        }
    },
    return_url(return_url) {
        if (return_url == undefined) {
            return localStorage.getItem('return_url');
        } else {
            localStorage.setItem('return_url', return_url);
        }
    },
    session_id: {
        hide() {
            localStorage.setItem('on_hide_time', moment().format("YYYY-MM-DD HH:mm:ss"));
        },
        show() {
            if (localStorage.getItem('on_hide_time') != null) {
                var diff = moment() - moment(localStorage.getItem('on_hide_time'));

                if (diff > 1 * 60 * 1000) {
                    this.revoke();
                }
            }
        },
        get() {
            var uuid = '';
            if (localStorage.getItem('session_id') == null) {
                uuid = uuidv4();
                uuid = uuid.replace(/-/g, '');
                localStorage.setItem('session_id', uuid);
            } else {
                uuid = localStorage.getItem('session_id');
            }
            return uuid;
        },
        revoke() {
            localStorage.removeItem('session_id');
        }
    },
    session_key(key) {
        if (key == undefined) {
            return localStorage.getItem('msk');
        } else {
            localStorage.setItem('msk', key);
        }
    },
    token(token) {
        if (token == undefined) {
            return localStorage.getItem('token');
        } else {
            localStorage.setItem('token', token);
        }
    },
    user(user) {
        if (user == undefined) {
            if (localStorage.getItem('user') == undefined) return null;
            return JSON.parse(localStorage.getItem('user'));
        } else {
            var keys = ["email", "name", "user_id", "user_id_type", "type", "company_name"];
            var _user = {};
            keys.forEach(k => {
                _user[k] = user[k];
            });
            localStorage.setItem('user', JSON.stringify(_user));
            this.session_key(user.session_key);
            this.user_id(user.user_id);
        }
    },
    is_login() {
        if (localStorage.getItem('user') == undefined) return false;
        return true;
    },
    setting(setting) {
        if (setting == undefined) {
            if (localStorage.getItem('setting') == undefined) {
                return {};
            } else {
                return JSON.parse(localStorage.getItem('setting'));
            }
        } else {
            localStorage.setItem('setting', JSON.stringify(setting));
        }
    },
    setting_key(id, value) {
        if (value == undefined) {
            var setting0 = this.setting();
            return setting0[id];
        } else {
            var setting = this.setting();
            setting[id] = value;
            this.setting(setting);
        }
    },
    save_user_id(save_user_id) {
        if (save_user_id == undefined) {
            return this.setting_key('save_user_id') == 'Y' ? true : false;
        } else {
            this.setting_key('save_user_id', save_user_id);
        }
    },
    user_id(user_id) {
        if (user_id == undefined) {
            return this.setting_key('user_id');
        } else {
            this.setting_key('user_id', user_id);
        }
    },

};