import { createRouter, createWebHashHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';

import LayoutBasic from "../layouts/LayoutBasic.vue";

import manager from "./manage_manager";
import manage_push from "./manage_push";
import manage_medicine from "./manage_medicine";
import manage_safety_letter from "./manage_safety_letter";
import manage_statics from "./manage_statics";
import manage_user from "./manage_user";
import my from "./my";
import user from "./user";

import ng from "@/assets/js/ng";
import storage from "@/assets/js/storage";

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: { layout: LayoutBasic },
    },
    {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    },
    {
        path: '/manage/korea',
        name: 'korea',
        component: () => import('../views/manage/korea/HomeView.vue'),
        meta: { layout: LayoutBasic },
    },
    ...manager,
    ...manage_medicine,
    ...manage_push,
    ...manage_safety_letter,
    ...manage_statics,
    ...manage_user,
    ...my,
    ...user,
    {
        path: '/manage/qna',
        name: 'manageQna',
        component: () => import('../views/manage/qna/ListView.vue'),
        meta: { layout: LayoutBasic, title: "1:1 문의 관리" },
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    console.log(from.path, "->", to.path);
    if (storage.is_login()) {
        if (to.path.startsWith("/user")) {
            router.replace("/");
            return;
        }
    } else {
        if (!to.path.startsWith("/user")) {
            ng.swal.alert("로그인이 필요합니다.");
            localStorage.setItem("return_url", to.fullPath);
            router.replace("/user/login");
            return;
        }
    }
    next();
});

export default router;
