const user_access_option = {
    animations: {
        y: {
            easing: 'easeInOutElastic',
            from: (ctx) => {
                if (ctx.type === 'data') {
                    if (ctx.mode === 'default' && !ctx.dropped) {
                        ctx.dropped = true;
                        return 0;
                    }
                }
            }
        },
    },
    hoverRadius: 8,
    hoverBackgroundColor: 'yellow',
    responsive: true,
    interaction: {
        mode: "index",
        intersect: false,
    },
    stacked: false,
    plugins: {
        datalabels: {
            display: false // 라벨 비활성화
        },
        title: {
            display: false,
            text: "",
        },
        tooltip: {
            callbacks: {
                label: function (context) {
                    let label = context.dataset.label || '';

                    var suffix = '';
                    if (label == '접속 유저수') suffix = ' 명';
                    else if (label == '접속 횟수') suffix = ' 회';
                    else if (label == '사용 시간') suffix = ' 분';

                    if (label) {
                        label += ': ';
                    }

                    label += context.formattedValue;
                    return label + suffix;
                }
            }
        }
    },
    scales: {
        y: {
            type: "linear",
            display: true,
            position: "left",
            title: {
                display: true,
                text: '접속 유저수'
            },
            beginAtZero: true
        },
        y1: {
            type: "linear",
            display: true,
            position: "left",
            grid: {
                drawOnChartArea: false,
            },
            title: {
                display: true,
                text: '접속 횟수'
            },
            beginAtZero: true
        },
        y2: {
            type: "linear",
            display: true,
            position: "right",
            grid: {
                drawOnChartArea: false,
            },
            title: {
                display: true,
                text: '사용 시간'
            },
            beginAtZero: true
        },
    },
};

const user_join_option = {
    animations: {
        y: {
            easing: 'easeInOutElastic',
            from: (ctx) => {
                if (ctx.type === 'data') {
                    if (ctx.mode === 'default' && !ctx.dropped) {
                        ctx.dropped = true;
                        return 0;
                    }
                }
            }
        },
    },
    hoverRadius: 8,
    hoverBackgroundColor: 'yellow',
    responsive: true,
    interaction: {
        mode: "index",
        intersect: false,
    },
    stacked: false,
    plugins: {
        datalabels: {
            display: false // 라벨 비활성화
        },
        title: {
            display: false,
            text: "",
        },
        tooltip: {
            callbacks: {
                label: function (context) {
                    let label = context.dataset.label || '';
                    var suffix = ' 명';
                    if (label) {
                        label += ': ';
                    }
                    label += context.formattedValue;
                    return label + suffix;
                }
            }
        }
    },
    scales: {
        y: {
            type: "linear",
            display: true,
            position: "left",
            title: {
                display: true,
                text: '전체 가입자수'
            },
            beginAtZero: true
        },
        y1: {
            type: "linear",
            display: true,
            position: "right",
            grid: {
                drawOnChartArea: false,
            },
            title: {
                display: true,
                text: '당일 가입자수'
            },
            beginAtZero: true,
            ticks: {
                stepSize: 1
            }
        }
    },
};


const options = {
    platform_user_access: {
        api: {
            type: 'statics',
            action: 'platform_user_access'
        },
        label: '최근 일주일간 사용자 접속 정보',
        datasets: [
            {
                label: "접속 유저수",
                data: [],
                borderWidth: 1,
                yAxisID: "y",
            },
            {
                label: "접속 횟수",
                data: [],
                borderWidth: 1,
                yAxisID: "y1",
            },
            {
                label: "사용 시간",
                data: [],
                borderWidth: 1,
                yAxisID: "y2",
            },
        ],
        type: "line",
        options: user_access_option,
        parsing: function (vm, response) {
            vm.chart_data.labels = [];
            vm.chart_data.datasets[0].data = [];
            vm.chart_data.datasets[1].data = [];
            vm.chart_data.datasets[2].data = [];

            response.data.data.forEach(item => {
                vm.chart_data.labels.push(item.date);

                if (item.ACCESS_USER != undefined) vm.chart_data.datasets[0].data.push(item.ACCESS_USER);
                else vm.chart_data.datasets[0].data.push(0);

                if (item.ACCESS_COUNT != undefined) vm.chart_data.datasets[1].data.push(item.ACCESS_COUNT);
                else vm.chart_data.datasets[1].data.push(0);

                if (item.ACCESS_TIME != undefined) vm.chart_data.datasets[2].data.push(parseInt(item.ACCESS_TIME / 60));
                else vm.chart_data.datasets[2].data.push(0);
            });
        }
    },
    platform_user_join: {
        api: {
            type: 'statics',
            action: 'platform_user_join'
        },
        label: '최근 일주일간 사용자 가입 현황',
        datasets: [
            {
                label: "전체 가입자수",
                data: [],
                borderWidth: 1,
                yAxisID: "y",
            },
            {
                label: "당일 가입자수",
                data: [],
                borderWidth: 1,
                yAxisID: "y1",
            },
        ],
        type: "line",
        options: user_join_option,
        parsing: function (vm, response) {
            vm.chart_data.labels = [];
            vm.chart_data.datasets[0].data = [];
            vm.chart_data.datasets[1].data = [];

            response.data.data.forEach(item => {
                vm.chart_data.labels.push(item.date);
                vm.chart_data.datasets[0].data.push(item.USER_TOTAL_COUNT);
                vm.chart_data.datasets[1].data.push(item.USER_JOIN_COUNT);
            });
        }
    },
    status_user_count: {
        api: {
            type: 'status',
            action: 'user_count'
        },
        label: '사용자 가입 분포',
        labels: [
            '약사',
            '환자',
            '연구진',
            '기타',
            '자문위원'
        ],
        datasets: [
            {
                data: [],
                backgroundColor: [
                    'rgb(255, 99, 132)',
                    'rgb(54, 162, 235)',
                    'rgb(255, 205, 86)',
                    'rgb(141, 148, 150)',
                    'rgb(161, 186, 117)'
                ],
            },
        ],
        type: "doughnut",
        options: {
            animation: {
                animateRotate: true,
                animateScale: true
            },
            responsive: false,
            plugins: {
                datalabels: {
                    formatter: (value) => {
                        return value + '명';
                    },
                    color: '#fff',
                    anchor: 'end',
                    align: 'start',
                    offset: 10
                },
                legend: {
                    position: 'top',
                },
                title: {
                    display: false,
                    text: 'Chart.js Pie Chart'
                },
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            let label = context.dataset.label || '';

                            if (label) {
                                label += ': ';
                            }
                            label += context.formattedValue + ' 명';
                            return label;
                        }
                    }
                }
            }
        },
        parsing: function (vm, response) {
            var data = response.data.data;

            var labels = ['약사', '환자', '연구진', '기타'];

            vm.chart_data.datasets[0].data = [];
            vm.chart_data.datasets[0].data.push(data['약사']);
            vm.chart_data.datasets[0].data.push(data['환자']);
            vm.chart_data.datasets[0].data.push(data['연구진']);
            vm.chart_data.datasets[0].data.push(data['기타']);

            if (data['자문'] != undefined) {
                vm.chart_data.datasets[0].data.push(data['자문']);
                labels.push('자문');
            }

            vm.chart_data.labels = labels;
        }
    },
    platform_user_access_g1: {
        api: {
            type: 'statics',
            action: 'platform_user_access',
            query: {
                cate: "환자"
            }
        },
        label: '최근 일주일간 환자 접속 정보',
        datasets: [
            {
                label: "접속 유저수",
                data: [],
                borderWidth: 1,
                yAxisID: "y",
            },
            {
                label: "접속 횟수",
                data: [],
                borderWidth: 1,
                yAxisID: "y1",
            },
            {
                label: "사용 시간",
                data: [],
                borderWidth: 1,
                yAxisID: "y2",
            },
        ],
        type: "line",
        options: user_access_option,
        parsing: function (vm, response) {
            vm.chart_data.labels = [];
            vm.chart_data.datasets[0].data = [];
            vm.chart_data.datasets[1].data = [];
            vm.chart_data.datasets[2].data = [];

            response.data.data.forEach(item => {
                vm.chart_data.labels.push(item.date);

                if (item.ACCESS_USER != undefined) vm.chart_data.datasets[0].data.push(item.ACCESS_USER);
                else vm.chart_data.datasets[0].data.push(0);

                if (item.ACCESS_COUNT != undefined) vm.chart_data.datasets[1].data.push(item.ACCESS_COUNT);
                else vm.chart_data.datasets[1].data.push(0);

                if (item.ACCESS_TIME != undefined) vm.chart_data.datasets[2].data.push(parseInt(item.ACCESS_TIME / 60));
                else vm.chart_data.datasets[2].data.push(0);
            });
        }
    },
    platform_user_access_g2: {
        api: {
            type: 'statics',
            action: 'platform_user_access',
            query: {
                cate: '약사'
            }
        },
        label: '최근 일주일간 약사 접속 정보',
        datasets: [
            {
                label: "접속 유저수",
                data: [],
                borderWidth: 1,
                yAxisID: "y",
            },
            {
                label: "접속 횟수",
                data: [],
                borderWidth: 1,
                yAxisID: "y1",
            },
            {
                label: "사용 시간",
                data: [],
                borderWidth: 1,
                yAxisID: "y2",
            },
        ],
        type: "line",
        options: user_access_option,
        parsing: function (vm, response) {
            vm.chart_data.labels = [];
            vm.chart_data.datasets[0].data = [];
            vm.chart_data.datasets[1].data = [];
            vm.chart_data.datasets[2].data = [];

            response.data.data.forEach(item => {
                vm.chart_data.labels.push(item.date);

                if (item.ACCESS_USER != undefined) vm.chart_data.datasets[0].data.push(item.ACCESS_USER);
                else vm.chart_data.datasets[0].data.push(0);

                if (item.ACCESS_COUNT != undefined) vm.chart_data.datasets[1].data.push(item.ACCESS_COUNT);
                else vm.chart_data.datasets[1].data.push(0);

                if (item.ACCESS_TIME != undefined) vm.chart_data.datasets[2].data.push(parseInt(item.ACCESS_TIME / 60));
                else vm.chart_data.datasets[2].data.push(0);
            });
        }
    },
    platform_user_access_consultation: {
        api: {
            type: 'statics',
            action: 'platform_user_access',
            query: {
                cate: '자문'
            }
        },
        label: '최근 일주일간 자문 접속 정보',
        datasets: [
            {
                label: "접속 유저수",
                data: [],
                borderWidth: 1,
                yAxisID: "y",
            },
            {
                label: "접속 횟수",
                data: [],
                borderWidth: 1,
                yAxisID: "y1",
            },
            {
                label: "사용 시간",
                data: [],
                borderWidth: 1,
                yAxisID: "y2",
            },
        ],
        type: "line",
        options: user_access_option,
        parsing: function (vm, response) {
            vm.chart_data.labels = [];
            vm.chart_data.datasets[0].data = [];
            vm.chart_data.datasets[1].data = [];
            vm.chart_data.datasets[2].data = [];

            response.data.data.forEach(item => {
                vm.chart_data.labels.push(item.date);

                if (item.ACCESS_USER != undefined) vm.chart_data.datasets[0].data.push(item.ACCESS_USER);
                else vm.chart_data.datasets[0].data.push(0);

                if (item.ACCESS_COUNT != undefined) vm.chart_data.datasets[1].data.push(item.ACCESS_COUNT);
                else vm.chart_data.datasets[1].data.push(0);

                if (item.ACCESS_TIME != undefined) vm.chart_data.datasets[2].data.push(parseInt(item.ACCESS_TIME / 60));
                else vm.chart_data.datasets[2].data.push(0);
            });
        }
    },
};

export default options;
