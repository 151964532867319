<template>
    <div class="HomeTable">
        <div class="btn_p_type" :class="{ btn_d: p_type == 'D', btn_w: p_type == 'W', btn_m: p_type == 'M' }">
            <button class="D" @click="change_p_type('D')">일간</button>
            <button class="W" @click="change_p_type('W')">주간</button>
            <button class="M" @click="change_p_type('M')">월간</button>
        </div>
        <BasicDataTable v-model:page="page" :headers="headers" :items="items" :items-per-page="itemsPerPage"
            :loading="is_loading" :sort-by="sort_by" multi-sort>

            <template v-slot:[`item.p_index`]="{ item }">
                {{ item.p_index }}
                <template v-if="item.p_type == 'D'">일차</template>
                <template v-if="item.p_type == 'W'">주차</template>
                <template v-if="item.p_type == 'M'">개월차</template>
                <template v-if="item.p_type == 'Q'">분기차</template>
            </template>

            <template v-slot:no-data>
                <div>사용량 정보가 없습니다.</div>
            </template>
            <template v-slot:[`footer.prepend`]>
                <div class="add_slot">
                    <BasicButton @click="dn_usage" v-if="use_dn_excel">엑셀 다운로드</BasicButton>
                </div>
            </template>
        </BasicDataTable>
    </div>
</template>
<script>
import BasicDataTable from '@/components/BasicDataTable'
import BasicButton from '@/components/BasicButton.vue'

export default {
    name: 'HomeTable',
    components: {
        BasicDataTable,
        BasicButton
    },
    props: {
        p_type: {
            type: String,
            default: 'D'
        },
        headers: {
            type: Array,
            default: () => {
                return [];
            }
        },
        items: {
            type: Object
        },
        is_loading: {
            type: Boolean,
            default: false
        },
        sort_by: {
            type: Array,
            default: () => {
                return [{ key: 'p_index', order: 'asc' }]
            }
        },
        use_dn_excel: {
            type: Boolean,
            default: true
        }
    },
    computed: {},
    data: () => ({
        page:1,
        itemsPerPage:10
    }),
    mounted() {

    },
    methods: {
        change_p_type(val) {
            this.$emit('change_p_type', val)
        },
        dn_usage() {
            this.$emit('dn_usage')
        }
    },
    watch: {}
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";

.btn_p_type {
    padding: 10px;

    button {
        border: 1px solid #475569;
        padding: 5px 20px;
        font-size: 16px;
        border-radius: 20px;
        margin-right: 10px
    }
}

.btn_p_type.btn_d {
    button.D {
        border: 1px solid #475569;
        background-color: #475569;
        color: white;
    }
}

.btn_p_type.btn_w {
    button.W {
        border: 1px solid #475569;
        background-color: #475569;
        color: white;
    }
}

.btn_p_type.btn_m {
    button.M {
        border: 1px solid #475569;
        background-color: #475569;
        color: white;
    }
}
</style>