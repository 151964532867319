import LayoutOnly from "../layouts/LayoutOnly.vue";

export default [
    {
        path: "/user/login",
        name: "userLogin",
        component: () => import("@/views/user/LoginView.vue"),
        meta: { layout: LayoutOnly, title: "로그인" },
    },
];
