import Layout from "../layouts/LayoutBasic.vue";

export default [
    {
        path: "/statics/person",
        name: "staticsPerson",
        component: () => import("@/views/manage/statics/PersonalStatics.vue"),
        meta: { layout: Layout, title: "개인별 정보 조회" },
    },
];
