import Layout from "../layouts/LayoutBasic.vue";

export default [
    {
        path: "/manage/safety_letter",
        name: "manageSafetyLetter",
        component: () => import("@/views/manage/safety_letter/ListView.vue"),
        meta: { layout: Layout, title: "안전성서한 관리" },
    },
];
