<template>
    <div class="ChartBox" :class="classNames">
        <div class="label">
            <span>{{ label }}</span>
            <v-btn size="small" icon="mdi-reload" variant="text" @click="load" v-if="reloadable"></v-btn>
        </div>
        <div class="content">
            <canvas ref="MyChart" />
        </div>
    </div>
</template>
<script>
import { Chart, registerables } from 'chart.js'
import ChartJSPluginDatalabels from 'chartjs-plugin-datalabels'

Chart.register(ChartJSPluginDatalabels, ...registerables)

import chart_options from '@/assets/js/chart_option'

export default {
    name: 'ChartBox',
    components: {},
    props: {
        type: {
            type: String,
            default: ''
        },
        reloadable: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        classNames() {
            var obj = {};

            obj[this.chart_type] = true;

            return obj;
        }
    },
    data: () => ({
        chart: null,
        label: '',
        chart_type: '',
        chart_data: {
            labels: []
        },
        chart_options: {
        },
    }),
    mounted() {
        this.load();
    },
    methods: {
        createChart() {

            if(this.chart != null) this.chart.destroy();

            this.chart = new Chart(this.$refs.MyChart, {
                type: this.chart_type,
                data: this.chart_data,
                options: this.chart_options
            })
        },
        load() {
            var vm = this;
            if (chart_options[this.type] != undefined) {
                var api_set = chart_options[this.type].api;

                this.label = chart_options[this.type].label;

                var query = {};
                if(api_set.query != undefined) query = api_set.query;

                this.$api[api_set.type][api_set.action]({},query).then(response => {
                    this.chart_type = chart_options[this.type].type;
                    this.chart_data.datasets = chart_options[this.type].datasets;

                    if (chart_options[this.type].labels != undefined) this.chart_data.labels = chart_options[this.type].labels;
                    if (chart_options[this.type].options != undefined) this.chart_options = chart_options[this.type].options;

                    chart_options[this.type].parsing(vm, response);

                    this.createChart();
                }).catch(e => {
                    console.error(e);
                })
            }
        }
    },
    watch: {}
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";

.ChartBox {
    display: flex;
    flex-direction: column;
    min-width: 600px;
    max-height: 380px;
    border-radius: 10px;
    background-color: white;

    .label {
        width: 100%;
        max-height: 40px;
        padding: 5px;
        font-size: 14px;
        text-align: center;
        display: flex;
        flex-direction: row;

        &>span {
            line-height: 40px;
            display: inline-block;
            flex-grow: 1;
            text-align: left;
            font-size: 16px;
            padding-left: 10px;
        }
    }

    .content {
        width: 100%;
        max-height: 400px;
        margin: 0px 20px 20px;
    }
}

.ChartBox.line {
    min-width: 600px;
}

.ChartBox.pie,
.ChartBox.doughnut {
    min-width: 300px;
}
</style>