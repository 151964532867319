import axios from 'axios';
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import Swal from "sweetalert2/dist/sweetalert2.js";

const methods = {
    swal: {
        opt: {
            confirmButtonText: "예",
            confirmButtonAriaLabel: "예",
            cancelButtonText: "아니요",
            cancelButtonAriaLabel: "아니요",
            showCancelButton: false,
            scrollbarPadding: false,
            focusConfirm: true,
            heightAuto: false,
            customClass: {
                cancelButton: "btn_cancel",
                confirmButton: "btn_confirm",
                denyButton: "btn_deny",
            },
        },
        alert: (text) => {
            var opt = JSON.parse(JSON.stringify(methods.swal.opt));
            opt.focusConfirm = true;

            if (typeof text == "string") opt.text = text;
            else opt = text;
            return new Promise((resolve) => {
                Swal.fire(opt).then((result) => {
                    resolve(result);
                });
            });
        },
        confirm: (text) => {
            var opt = JSON.parse(JSON.stringify(methods.swal.opt));
            opt.showCancelButton = true;

            if (typeof text == "string") opt.text = text;
            else {
                Object.keys(text).forEach((key) => {
                    opt[key] = text[key];
                });
            }
            return new Promise((resolve) => {
                Swal.fire(opt).then((result) => {
                    resolve(result);
                });
            });
        },
    },
    isApple: () => {
        if (navigator.vendor.indexOf("Apple") > -1) return true;
        return false;
    },
    isNumber: (str) => {
        if (typeof str == "number") return true;
        else if (typeof str == "string") {
            str = str.trim();
            if (str == "") return false;
            if (isNaN(Number(str))) return false;
            return true;
        } else return false;
    },
    clone: (obj) => {
        return JSON.parse(JSON.stringify(obj));
    },
    clean_item_name(txt) {
        if (txt.indexOf('(') != -1) {
            txt = txt.substring(0, txt.indexOf('('));
        }
        return txt;
    },
    formatDate: (obj) => {
        var val = obj;
        val = String(val);
        val = val.replace(" (한국 표준시)", "");
        return moment(val).format("YYYY-MM-DD");
    },
    modal: {
        is_open: false,
        is_menu_open: false,
        scrollTop: 0,
        scrollLeft: 0,
        list: [],
        last_time: function () {
            if (this.list.length == 0) return null;
            else return this.list[this.list.length - 1];
        },
        close() {
            this.list.pop();
            this.z_index--;
            if (this.list.length == 0) {
                this.is_open = false;
                this.z_index = 100;
                document.documentElement.classList.remove('no_scroll');
                document.body.classList.remove('no_scroll');
            }
        },
        open() {
            if (this.z_index == 100) {
                document.documentElement.classList.add('no_scroll');
                document.body.classList.add('no_scroll');
            }
        },
        menu_close() {
            this.is_menu_open = false;
            if (this.list.length == 0) {
                document.documentElement.classList.remove('no_scroll');
                document.body.classList.remove('no_scroll');
            }
        },
        menu_open() {
            this.is_menu_open = true;
            this.open();
        },
        modal_open() {
            this.is_open = true;
            this.open();
            this.z_index++;
        },
        z_index: 100
    },
    checkIdPasswd(id, passwd) {
        if (id == undefined || id.length < 5) return false;

        for (var i = 0; i < id.length - 4; i++) {
            var c = id.substring(i, (i + 5));
            if (passwd.indexOf(c) != -1) return false;
        }
        return true;
    },
    checkPasswdContinues(input) {
        if (typeof input !== 'string' || input.length < 3) {
            return false;
        }

        for (let i = 0; i < input.length - 2; i++) {
            const currentSubstring = input.slice(i, i + 3);
            const char1 = currentSubstring[0];
            const char2 = currentSubstring[1];
            const char3 = currentSubstring[2];

            if (char2.charCodeAt(0) === char1.charCodeAt(0) + 1 && char3.charCodeAt(0) === char2.charCodeAt(0) + 1) {
                return false;
            }
        }

        return true;
    },
    uuid() {
        return uuidv4().replace(/-/g, '');
    },
    store: {
        is_open_menu: false
    },
    download(url, fileName) {
        axios({
            method: 'GET',
            url: url,
            responseType: 'blob' // 가장 중요함
        })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            });
    }
};

export default methods;
