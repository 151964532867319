import Layout from "../layouts/LayoutBasic.vue";

export default [
    {
        path: "/manage/user",
        name: "manageUser",
        component: () => import("@/views/manage/user/ListView.vue"),
        meta: { layout: Layout, title: "사용자 관리" },
    },
    {
        path: "/manage/status",
        name: "manageStatus",
        component: () => import("@/views/manage/user/StatusView.vue"),
        meta: { layout: Layout, title: "관리" },
    },
];
