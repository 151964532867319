<template>
    <v-app>
        <component :is="$route.meta.layout || 'div'"></component>
    </v-app>
</template>
<script>
import storage from '@/assets/js/storage';

const packageInfo = require('../package.json');

export default {
    name: 'App',
    components: {},
    props: {},
    computed: {},
    data: () => ({
        user: {
            update_count: 0,
            name: '',
            company: ''
        },
        time: {
            user: 0
        },
        is_open_menu: false,
        setting:{
            version: ''
        },
        table_footer_pros: {
            "items-per-page-options": [10, 15, 20, 30, 50, -1],
            "items-per-page-text": "페이지 당 보여질 갯수",
            pageText: "총 {2}건",
        },
    }),
    mounted() {
        this.setting.version = packageInfo.version;
        this.load_user();
    },
    methods: {
        load_user() {
            this.$api.user.is_login().then(response => {
                if (response.data.user != undefined) {
                    this.set_user(response.data.user);
                } else {
                    this.set_user(null);
                }
            }).catch(err => {
                console.error(err);
            })
        },
        menu_open() {
            this.is_open_menu = this.is_open_menu == true ? false : true;
        },
        set_user(user) {
            if (user == null) {
                this.user = {};
                storage.logout();
            } else {
                this.user = user;
                this.user.update_count = 0;
                storage.user(user);
            }
            this.time.user = this.time.user + 1;
            this.user.update_count++;
        },
    },
    watch: {}
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";
</style>
