// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';

// Vuetify
import { createVuetify } from 'vuetify';
import DateFnsAdapter from "@date-io/date-fns";
import { ko } from "date-fns/locale";

export default createVuetify({
    locale: {
        //locale: 'ko'
    },
    date: {
        adapter: DateFnsAdapter,
        locale: {
            en: ko
        },
    },
});
