import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';

import api from '@/assets/js/api.js';
import common from '@/assets/js/common.js';

import 'sweetalert2/dist/sweetalert2.min.css';
import '@/assets/css/style.css';
import '@/assets/css/style.scss';


loadFonts();

createApp(App)
    .use(router)
    .use(store)
    .use(vuetify)
    .use(common)
    .use(api)
    .mount('#app');
