
import filters from '@/assets/js/filters';
import methods from '@/assets/js/ng';

export default {
    install: (app) => {
        app.config.globalProperties.$alert = methods.swal.alert;
        app.config.globalProperties.$confirm = methods.swal.confirm;

        app.config.globalProperties.$top = function() {
            window.scrollTo(0, 0);
        },

        app.config.globalProperties.$width = function() {
            return document.body.clientWidth;
        },

        app.config.globalProperties.$isApple = methods.isApple;
        app.config.globalProperties.$isNumber = methods.isNumber;
        app.config.globalProperties.$playVoice = methods.playVoice;

        app.config.globalProperties.$filters = filters;

        String.prototype.left = function(length){
            if(this.length <= length){
                return this;
            } else{
                return this.substring(0, length);
            }
        };
          
        String.prototype.right = function(length){
            if(this.length <= length){
               return this;
            } else {
               return this.substring(this.length - length, this.length);
            }
        };
    }
  };