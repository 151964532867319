<template>
    <v-layout class="rounded rounded-md">
        <v-system-bar height="32">
            <v-btn class="btn_menu" icon="mdi-menu" variant="text" @click="menu_open"
                v-if="$root.is_open_menu == false"></v-btn>
            <v-btn class="btn_menu" icon="mdi-menu-open" variant="text" @click="menu_open"
                v-if="$root.is_open_menu == true"></v-btn>
            안전e약
            <v-spacer />
            <v-btn class="ms-2" icon="mdi-power" variant="text" @click="logout"></v-btn>
        </v-system-bar>

        <v-navigation-drawer :class="{ 'on': $root.is_open_menu }" :key="$root.user.update_count">
            <v-list>
                <v-list-item :subtitle="$root.user.company_name" :title="$root.user.name">
                    <template v-slot:prepend>
                        <v-avatar color="blue-darken-4">
                            <span class="text-h7">{{cname($root.user.name)}}</span>
                        </v-avatar>
                    </template>
                </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-list>
                <v-list-item v-for="menu in menus" :key="menu.title" :title="menu.title" :prepend-icon="menu.icon"
                    :to="menu.to" @click="go(menu)"></v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list>
                <v-list-item :subtitle="$root.setting.version" title="버전"></v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-main style="min-height: 300px;">
            <div class="title" v-if="title != ''">
                <h3>{{ title }}</h3>
            </div>
            <div class="TheView" :class="viewClass"><router-view></router-view></div>
        </v-main>
    </v-layout>
</template>
<script>
import jsonMenu from '@/assets/data/menu.json'

export default {
    name: 'layoutBasic',
    components: {

    },
    data: () => ({
        is_menu_open: false
    }),
    computed: {

        viewClass() {
            var obj = {};
            obj[this.$route.name] = true;
            return obj
        },
        menus() {
            var arr = jsonMenu;
            return arr.filter((menu) => (
                (menu.f_user == undefined || menu.f_user <= this.$root.user.f_user) && (menu.f_manager == undefined || menu.f_manager <= this.$root.user.f_manager)
                && (menu.f_korea == undefined || menu.f_korea <= this.$root.user.f_korea)
                && (menu.f_status == undefined || menu.f_status <= this.$root.user.f_status)
            ));

        },
        title: function () {
            if (this.$route.meta && this.$route.meta.title)
                return this.$route.meta.title;
            return "";
        },
    },
    mounted() {
    },
    methods: {
        cname(val) {
            if(val == undefined || val == null) return '';
            if(val.length < 2) return val;
            return val.substring(val.length-2,val.length);
        },
        go() {
            this.$root.is_open_menu = false;
        },
        logout() {
            this.$confirm('로그아웃할까요?').then(rs => {
                if (rs.isConfirmed) {
                    this.$api.user.logout().then(() => {
                        this.$root.set_user(null);
                        this.$router.replace('/user/login');
                    })
                }
            })
        },
        menu_open() {
            this.$root.menu_open();
        }
    }
}
</script>
<style lang="scss" scoped>
.v-main {
    display: flex;
    flex-direction: column;

    &>.title {
        padding: 20px 18px 5px 18px;

        h3:after {
            content: "";
            display: block;
            width: 10px;
            border-bottom: 2px solid grey;
            width: 200px;
            margin-top: 5px;
        }
    }
}

.v-system-bar {
    padding: 0px;
    background-color: black;
    color: white;
}

.v-navigation-drawer.on {
    transform: translateX(0%) !important;
}

.TheView {
    padding: 10px 8px 20px 8px;
    width: 100%;
    height: calc(100vh - var(--v-layout-top) - 56px);
    overflow-y: auto;
}

.TheView.home, .TheView.korea {
    background-color: #edeef2;
    height: calc(100vh - var(--v-layout-top));
}

@media (min-width: 300px) {
    .TheView {
        padding: 20px 18px;
    }
}

@media (min-width: 1280px) {
    .v-system-bar {
        padding-left: 10px;
    }

    .btn_menu {
        display: none;
    }
}
</style>