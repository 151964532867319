import Layout from "../layouts/LayoutBasic.vue";

export default [
    {
        path: "/manage/push",
        name: "managePush",
        component: () => import("@/views/manage/push/ListView.vue"),
        meta: { layout: Layout, title: "푸시메세지 관리" },
    },
];
