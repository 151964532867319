import Layout from "../layouts/LayoutBasic.vue";

export default [
    {
        path: "/my/info",
        name: "myInfo",
        component: () => import("@/views/my/InfoView.vue"),
        meta: { layout: Layout, title: "나의 정보" },
    },
];
