import axios from "axios";
import { message } from "@/assets/js/messages";
import storage from "@/assets/js/storage";

import router from "@/router";

const methods = {
    make_url: (url, params, query) => {
        var _url = url;
        var arr_url = _url.split("/");
        arr_url.forEach((chip, i) => {
            if (chip.startsWith(":")) {
                if (params[chip.substring(1)]) {
                    arr_url[i] = params[chip.substring(1)];
                } else {
                    arr_url[i] = "";
                }
            }
        });
        _url = arr_url.join("/");
        if (query) {
            var arr = [];
            Object.keys(query).forEach((q) => {
                arr.push(`${q}=${encodeURIComponent(query[q])}`);
            });

            if (arr.length > 0) {
                _url += "?" + arr.join("&");
            }
        }
        _url = _url.replace("//", "/");
        _url = _url.replace("//", "/");

        if (_url.endsWith("/")) {
            _url = _url.substring(0, _url.length - 1);
        }
        return _url;
    },
    resource: (url, opt) => {
        var api = {};
        Object.keys(opt).forEach((key) => {
            api[key] = function (params, query, body) {
                return new Promise((resolve, reject) => {
                    var _params = {};
                    var _query = {};

                    if (opt[key].params) {
                        Object.keys(opt[key].params).forEach((k1) => {
                            _params[k1] = opt[key].params[k1];
                        });
                    }
                    if (params) {
                        Object.keys(params).forEach((k1) => {
                            _params[k1] = params[k1];
                        });
                    }

                    if (opt[key].query) {
                        Object.keys(opt[key].query).forEach((k1) => {
                            _query[k1] = opt[key].query[k1];
                        });
                    }
                    if (query) {
                        Object.keys(query).forEach((k1) => {
                            _query[k1] = query[k1];
                        });
                    }

                    var _url = methods.make_url(url, _params, _query);

                    var options = {};
                    options.url = _url;
                    options.method = opt[key].method;

                    if (opt.headers != undefined) {
                        options.headers = opt.headers;
                    }

                    if (opt[key].headers != undefined) {
                        Object.keys(opt[key].headers).forEach((key) => {
                            options.headers[key] = opt[key].headers[key];
                        });
                    }

                    if (body != undefined) options.data = body;

                    axios(options)
                        .then(function (response) {
                            resolve(response);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                });
            };
        });
        return api;
    },
};

export default {
    install: (app) => {
        axios.interceptors.request.use((config) => {
            if (storage.session_key() != undefined)
                config.headers.msk = storage.session_key();
            return config;
        });

        // 응답 에러 관련
        axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                var path = router.currentRoute.value.fullPath;

                if (error.code == "ERR_BAD_RESPONSE") {
                    app.config.globalProperties
                        .$alert("서버오류가 발생하였습니다. 잠시후 다시 사용해보세요.")
                        .then(() => {
                            return Promise.reject(error);
                        });
                }

                /*
                        if (error.config.url == '/api/user/is_login' && document.location.hash == '#/user/login') {
                            return Promise.reject(error);
                        }*/
                if (error.response.data.message != undefined) {
                    if (message[error.response.data.message] != undefined) {
                        if (
                            error.response.data.message == "need_to_login" ||
                            error.response.data.message == "need_to_re_login"
                        ) {
                            if (path != undefined) localStorage.setItem("return_url", path);
                            else localStorage.removeItem("return_url");

                            app.config.globalProperties
                                .$alert(message[error.response.data.message])
                                .then(() => {
                                    storage.clear();
                                    router.push("/user/login");
                                });
                        } else {
                            app.config.globalProperties.$alert(
                                message[error.response.data.message]
                            );
                        }
                    } else {
                        app.config.globalProperties.$alert(error.response.data.message);
                    }
                }
                return Promise.reject(error);
            }
        );

        var api = {};

        api.manage = {};

        api.manage.manager = methods.resource("/api/manage/manager/:uuid/:cmd", {
            list: {
                method: "get",
            },
            detail: {
                method: "get",
            },
            hold: {
                method: "put",
                params: {
                    cmd: "hold",
                },
            },
            ing: {
                method: "put",
                params: {
                    cmd: "ing",
                },
            },
            delete: {
                method: "delete",
            },
            passwd: {
                method: "put",
                params: {
                    cmd: "passwd",
                },
            },
            register: {
                method: "post",
            },
            otp: {
                method: "get",
                params: {
                    cmd: "otp",
                },
            },
            otp_renew: {
                method: "put",
                params: {
                    cmd: "otp",
                },
            },
        });

        api.manage.medicine = methods.resource(
            "/api/manage/medicine/:item_seq/:cmd",
            {
                list: {
                    method: "get",
                },
                get: {
                    method: "get",
                },
                api: {
                    method: "get",
                    params: {
                        cmd: "api",
                    },
                },
                history: {
                    method: "get",
                    params: {
                        cmd: "history",
                    },
                },
                memo_update: {
                    method: "put",
                    params: {
                        cmd: "memo",
                    },
                },
            }
        );

        api.manage.drug = methods.resource("/api/manage/drug/:act", {
            bookmarks: {
                method: "get",
                params: {
                    act: "bookmarks",
                },
            },
            search: {
                method: "get",
                params: {
                    act: "search",
                },
            },
            takes: {
                method: "get",
                params: {
                    act: "takes",
                },
            },
            views: {
                method: "get",
                params: {
                    act: "views",
                },
            },
        });

        api.manage.korea = {};

        api.manage.korea.statics = methods.resource("/api/manage/korea/statics/:cmd", {
            all: {
                method: "get",
                params: {
                    cmd: "A",
                },
            },
            users: {
                method: "get",
                params: {
                    cmd: "users",
                },
            },
        });

        api.manage.korea.users = methods.resource("/api/manage/korea/users/:uuid/:cmd", {
            list: {
                method: "get"
            },
            statics: {
                method: "get",
                params: {
                    cmd: "usage",
                },
            },
        });

        api.manage.prescription = methods.resource(
            "/api/manage/prescription/:uuid/:cmd",
            {
                list: {
                    method: "get",
                },
                history: {
                    method: "get",
                    params: {
                        cmd: "history",
                    },
                },
            }
        );

        api.manage.push = methods.resource("/api/manage/push/:seq/:cmd", {
            list: {
                method: "get",
            },
            detail: {
                method: "get",
            },
            add: {
                method: "post",
            },
            booking: {
                method: "put",
                params: {
                    cmd: "booking",
                },
            },
            cancel: {
                method: "put",
                params: {
                    cmd: "cancel",
                },
            },
            send_test: {
                method: "put",
                params: {
                    cmd: "test",
                },
            },
            update: {
                method: "put",
            },
        });

        api.manage.qna = {};

        api.manage.qna.categories = methods.resource("/api/manage/qna/categories", {
            list: {
                method: "get",
            },
        });

        api.manage.qna.questions = methods.resource(
            "/api/manage/qna/questions/:uuid/:cmd",
            {
                list: {
                    method: "get",
                },
                get: {
                    method: "get",
                },
                write: {
                    method: "post",
                },
                delete: {
                    method: "delete",
                },
            }
        );

        api.manage.safety_letter = methods.resource(
            "/api/manage/safety_letter/:no/:cmd/:seq",
            {
                list: {
                    method: "get",
                },
                detail: {
                    method: "get",
                },
                list_medicine: {
                    method: "get",
                    params: {
                        cmd: "medicines",
                    },
                },
                add_medicine: {
                    method: "post",
                    params: {
                        cmd: "medicines",
                    },
                },
                del_medicine: {
                    method: "delete",
                    params: {
                        cmd: "medicines",
                    },
                },
                set_hide: {
                    method: "put",
                    params: {
                        cmd: "set_hide",
                    },
                },
                set_show: {
                    method: "put",
                    params: {
                        cmd: "set_show",
                    },
                },
            }
        );

        api.manage.temp = methods.resource("/api/manage/temp/:cmd", {
            가입자코드_현황: {
                method: "get",
                params: {
                    cmd: "join_code_status",
                },
            },
            워크샵_가입리스트: {
                method: "get",
                params: {
                    cmd: "join_list",
                },
            },
        });

        api.manage.user = methods.resource(
            "/api/manage/user/:uuid/:cmd/:cmd_uuid/:cmd_act",
            {
                list: {
                    method: "get",
                },
                detail: {
                    method: "get",
                },
                hold: {
                    method: "put",
                    params: {
                        cmd: "hold",
                    },
                },
                ing: {
                    method: "put",
                    params: {
                        cmd: "ing",
                    },
                },
                delete: {
                    method: "delete",
                },
                history_bio: {
                    method: "get",
                    params: {
                        cmd: "history/bio",
                    },
                },
                notification: {
                    method: "get",
                    params: {
                        cmd: "notification",
                    },
                },
                dose_notification: {
                    method: "get",
                    params: {
                        cmd: "dose_notification",
                    },
                },
                dose_notification_log: {
                    method: "get",
                    params: {
                        cmd: "dose_notification",
                        cmd_act: "log",
                    },
                },
                statics_daily_usage: {
                    method: "get",
                    params: {
                        cmd: "statics/daily_usage",
                    },
                },
            }
        );

        api.medicine = methods.resource("/api/medicines/:code/:cmd", {
            detail: {
                method: "get",
            },
            search: {
                method: "get",
            },
        });

        api.my = {};
        api.my.info = methods.resource("/api/my/info/:cmd", {
            detail: {
                method: "get",
            },
            update: {
                method: "put",
            },
            password: {
                method: "put",
                params: {
                    cmd: "password",
                },
            },
            otp: {
                method: "get",
                params: {
                    cmd: "otp",
                },
            },
            otp_renew: {
                method: "put",
                params: {
                    cmd: "otp",
                },
            },
        });

        api.statics = methods.resource("/api/statics/:cmd/:cmd2", {
            platform_user_access: {
                method: "get",
                params: {
                    cmd: "platform/user_access",
                },
            },
            platform_user_join: {
                method: "get",
                params: {
                    cmd: "platform/user_join",
                },
            },
            /*
                  platform_user_usage: {
                      method: "get",
                      params: {
                          cmd: "platform/user_usage",
                      },
                  },*/
            platform_usage_group_A: {
                method: "get",
                params: {
                    cmd: "/platform/usage/group/A",
                },
            },
            platform_usage_group_U: {
                method: "get",
                params: {
                    cmd: "/platform/usage/group/U",
                },
            },
            platform_usage_group_P: {
                method: "get",
                params: {
                    cmd: "/platform/usage/group/P",
                },
            },
            platform_usage_group_G: {
                method: "get",
                params: {
                    cmd: "/platform/usage/group/G",
                },
            },
            platform_usage_user: {
                method: "get",
                params: {
                    cmd: "/platform/usage/user",
                },
            },
            platform_user_list: {
                method: "get",
                params: {
                    cmd: "/platform/user",
                },
            },
        });

        api.status = methods.resource("/api/status/:cmd", {
            user_count: {
                method: "get",
                params: {
                    cmd: "user/count",
                },
            },
            user_count_join_today: {
                method: "get",
                params: {
                    cmd: "user/count/join_today",
                },
            },
            user_count_online: {
                method: "get",
                params: {
                    cmd: "user/count/online",
                },
            },
            user_count_total: {
                method: "get",
                params: {
                    cmd: "user/count/total",
                },
            },
            user_list_join_today: {
                method: "get",
                params: {
                    cmd: "user/list/join_today",
                },
            },
            user_list_online: {
                method: "get",
                params: {
                    cmd: "user/list/online",
                },
            },
            user_list_recent: {
                method: "get",
                params: {
                    cmd: "user/list/recent",
                },
            },
            user_list_connected: {
                method: "get",
                params: {
                    cmd: "user/list/connected",
                },
            },
            user_list_not_connected_long_term: {
                method: "get",
                params: {
                    cmd: "user/list/not_connected_long_term",
                },
            },
            user_list_not_register_bio: {
                method: "get",
                params: {
                    cmd: "user/list/not_register_bio",
                },
            },
        });

        api.user = methods.resource("/api/user/:cmd", {
            is_login: {
                method: "get",
                params: {
                    cmd: "is_login",
                },
            },
            login: {
                method: "post",
                params: {
                    cmd: "login",
                },
            },
            logout: {
                method: "get",
                params: {
                    cmd: "logout",
                },
            },
        });

        api.utils = methods.resource("/api/utils/:cmd", {
            medicine_xml_to_json: {
                method: "post",
                params: {
                    cmd: "medicine/xml_to_json",
                },
            },
            medicine_modified_category: {
                method: "get",
                params: {
                    cmd: "medicine/modified_category",
                },
            },
        });

        app.config.globalProperties.$api = api;
    },
};
