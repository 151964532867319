<template>
    <div class="CountBox">
        <div class="label">
            <span>{{ label }}</span>
            <v-btn size="small" icon="mdi-reload" variant="text" @click="load" v-if="reloadable"></v-btn>
        </div>
        <div class="content" :class="colorClass">{{ count }}</div>
    </div>
</template>
<script>
import report from '@/assets/data/report.json'
export default {
    name: 'CountBox',
    components: {},
    props: {
        color:{
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: '',
        },
        reloadable:{
            type: Boolean,
            default:false
        }
    },
    computed: {
        colorClass() {
            var obj = {};
            if(this.color != '') {
                obj[this.color] = true;
            }
            return obj;
        }
    },
    data: () => ({
        count: 0,
        label: '',
    }),
    mounted() {
        this.label = report[this.type].label;
        this.load();
    },
    methods: {
        load() {
            var query = report[this.type].query != undefined ? report[this.type].query : {};

            this.$api.status[report[this.type].api]({},query).then(response => {
                this.count = response.data.data.count;
            })
        }
    },
    watch: {}
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";

.CountBox {
    display: flex;
    flex-direction: column;
    min-width: 200px;
    min-height: 90px;
    border-radius: 10px;
    background-color: white;;

    .label {
        width: 100%;
        max-height: 40px;
        padding: 5px;
        font-size: 14px;
        text-align: center;
        display: flex;
        flex-direction: row;

        & > span {
            line-height: 40px;
            display:inline-block;
            flex-grow: 1;
            text-align: left;
            font-size: 16px;
            padding-left:10px;
        }
    }

    .content {
        width: 100%;
        font-size: 52px;
        line-height: 100%;
        font-weight: 700;
        text-align: center;
        flex-grow: 1;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .foot {
        min-height: 40px;
    }
}

.content.red {
    color:#D50000;
}

.content.blue {
    color:#1A237E;
}

.content.yellow {
    color:#F9A825;
}

.content.grey {
    color:#616161;
}

.content.purple {
    color:#6A1B9A;
}
</style>