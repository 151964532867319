import Layout from "../layouts/LayoutBasic.vue";

export default [
    {
        path: "/manage/medicine",
        name: "manageMedicine",
        component: () => import("@/views/manage/medicine/ListView.vue"),
        meta: { layout: Layout, title: "의약품 허가사항 관리" },
    },
];
