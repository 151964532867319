export const message = {
    login_fail : '아이디 비밀번호가 일치하지 않습니다.',
    name_is_invalid : '이름은 2~20글자로 입력해주세요.',
    user_id_is_invalid : '유저아이디가 유효한 형식이 아닙니다.',
    email_is_invalid : '이메일이 유효한 형식이 아닙니다.',
    mobile_is_invalid : '휴대폰 번호가 유효한 형식이 아닙니다.',
    passwd_is_invalid : '비밀번호가 유효한 형식이 아닙니다.',
    auth_num_is_invalid : '인증번호가 유효한 형식이 아닙니다.',

    new_passwd_is_invalid : '비밀번호가 유효한 형식이 아닙니다.',
    invalid_otp : 'OTP가 유효하지 않습니다.',

    need_to_confirm_email : "이메일 인증이 필요합니다.",
    need_to_login : "로그인이 필요합니다.",
    need_to_re_login : "로그인이 필요합니다.",

    user_id_is_dup : "유저 아이디가 중복입니다.",
    email_is_dup : "이메일이 중복입니다.",
    mobile_is_dup : "휴대폰번호가 중복입니다.",

    auth_num_is_mismatch : "인증번호가 일치하지 않습니다.",

    uuid_is_invalid : "유효한 접근이 아닙니다. (uuid)",
    type_is_invalid : "유효한 접근이 아닙니다.",
    password_mismatch : '비밀번호가 일치하지 않습니다.',
};