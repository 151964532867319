<template>
    <v-text-field variant="outlined" hide-details="auto" density="compact"></v-text-field>
</template>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";

.rt {
    
    &:deep(.v-field__input) {
        text-align: right;
        padding-right:10px;
    }
}
</style>