<template>
    <button class="btn" block>
        <slot></slot>
    </button>
</template>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";

.btn {
  border: 2px solid $b-g-6;
  min-width: 100px;
  color:$b-g-6;
  border-radius: 5px;
  padding: 5px 20px;
  min-height: 32px;
  font-size: 14px;
  font-weight: 700;
  line-height: 130%;
  background-color: white;
}

.btn.p {
    border: 2px solid $b-8;
    background-color: $b-8;
    color:white;
}

.btn.s {
    border: 2px solid $b-g-6;
    background-color: $b-g-6;
    color:white;
}

.btn.e {
    border: 2px solid $r-6;
    background-color: $r-6;
    color:white;
}

.btn.w {
    border: 2px solid $y-6;
    background-color: $y-6;
    color:white;
}

button.btn:disabled,
button.btn[disabled]{
  border: 2px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
</style>