<template>
    <v-main class="d-flex align-center justify-center" style="min-height: 300px;">
        <router-view></router-view>
    </v-main>
</template>
<script>
export default {
  name: 'layoutOnly',
  components: {

  },
  data: () => ({
    simple: true
  }),
}
</script>
<style lang="scss" scoped>
.TheView {
  padding: 20px 8px;
  max-width: 800px;
  margin: 0px auto;
}

@media (min-width: 300px) {
  .TheView {
    padding: 20px 18px;
  }
}
</style>